import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const TableOfContents = ({ toc, slug, ...props }) => (

    typeof toc.items === 'undefined' ? null : (
        <div className="bg-gray-100 px-2 pt-1 pb-2">
        <h2 className="text-center text-lg">目次</h2>
        <div className="ml-4">
        <ul>
        {toc.items.map(i => (         
          <li key={i.url}>
          <AnchorLink  className="underline" to={`/blog/${slug}${i.url}`}
          title={i.title} />
          </li>          
        ))}
        </ul>
        </div>
      </div>
  )
    
);


export default TableOfContents;