import React from "react";
import { GatsbyImage } from 'gatsby-plugin-image';

import ExternalLink from "../static/svg/icon-external-link.inline.svg"

const BookCard = ({ to, publisher, date, image, alt, title, author, isbn, ...props }) => (

    <div className="rounded border border-c-acc">

        <div className="overflow-hidden" >
            <a href={to} target="_blank" rel="noopener noreferrer" >
                <GatsbyImage
                    alt={alt}
                    image={image}
                    className="max-w-1/4 float-left mr-2 my-2 ml-2"
                />
                <p className="font-semibold">
                    {title}
                    <ExternalLink className="h-5 w-5 align-middle inline-block mr-2 " />
                </p>
            </a>
            <p className="text-sm sm:text-base">{author}<br />
                ISBN：{isbn}<br />
                出版社：
                <a href={to} target="_blank" rel="noopener noreferrer">
                    {publisher}
                    <ExternalLink className="h-5 w-5 align-middle inline-block mr-2 " />
                </a><br />
                発売日：{date}
            </p>
        </div>

    </div>



  );

export default BookCard;