import React from "react";

import ExternalLink from "../static/svg/icon-external-link.inline.svg";
import QuotesLeft from "../static/svg/icon-quotes-left.inline.svg";

const Quote = ({ quoteString, citeHref, citeString, ...props }) => (

    <blockquote className="relative p-4 border-l-4 bg-gray-100  border-c-acc mt-2 mb-7">
        <span className="text-gray-400"><QuotesLeft className="w-5 h-5 fill-current absolute top-2 " /></span>
        <p className="pt-1" dangerouslySetInnerHTML={{ __html:quoteString }} />
        <p className="text-right mb-0">
            <cite>
            <a href={citeHref} target="_blank" rel="noopener noreferrer">{citeString}<ExternalLink className="h-4 w-4 align-middle inline-block mr-2 ml-1" /></a>より
        </cite></p>
    </blockquote>
    
);


export default Quote;