import * as React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx' 
import { MDXProvider } from '@mdx-js/react';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image' 

import Update from "../../static/svg/icon-update.inline.svg";

import LayoutSub from '../../components/layoutSub';
import SEO from '../../components/seo';
import Quote from '../../components/quote';
import BookCard from '../../components/bookCard';
import Attribution from '../../components/attribution';
import BlogCards from '../../components/blogCards';
import ExLink from '../../components/exLink';
import TableOfContents from '../../components/tableOfContents';
import AdsenseInArticle from '../../components/adsense/adsenseInArticle';
import AdsenseDisplay from '../../components/adsense/adsenseDisplay';
import CtaBlog from '../../components/ctaBlog';

function BlogPost  ({  
  data: {
    mdx: { tableOfContents,
           frontmatter: { title,  lastmod, description, hero_image, hero_image_alt, embeddedImagesLocal },
           body, slug,
         },
        }, 
  location: { pathname },
  pageContext: {breadcrumb: { crumbs, },},
  })  {

  const image = getImage(hero_image) 
  const imgSrc = getSrc(image)
  const localImages = embeddedImagesLocal

  const newCrumbLabel =
  ( (title).length > 10) 
   ? `${title.substr(0, 10)}…`
   :  title;

  crumbs[crumbs.length - 1].crumbLabel = newCrumbLabel;
 
  return (

    <LayoutSub crumbs={crumbs} isBlog pathname={pathname}>
      <SEO
        pageurl={pathname}
        pagetitle={title}
        description={description}
        eyeCatchImgSrc={imgSrc}
      />
      <div className="heading-b">
        <h1>{title}</h1>
      </div>

      <p className="text-right -mt-5 mb-3.5"> <Update className="h-4 w-4 align-text-bottom  inline-block mr-2 " />
        {lastmod}</p>

      <GatsbyImage
        image={image}
        alt={hero_image_alt}
        loading="eager"
      />

      <p className="my-4">こんにちは。<br />”アメリカの医学・オステオパシー” という手技で身体の調整をしております、ぺんぎん堂の橋本智子です。当ブログにお越しいただき、ありがとうございます。<br /></p>

      <section id="blog-content">

        <MDXProvider
          components={{
            Quote,
            BookCard,
            Attribution,
            BlogCards,
            ExLink,
            TableOfContents,
            AdsenseInArticle,
            AdsenseDisplay,
            CtaBlog,
            em: props => <span {...props} className="marker" />,
            h2: props => <div className="mt-4 mb-2"><h2 {...props} className=" my-2 py-2 text-c-base bg-c-main " /></div>,
            h3: props => <div className="mt-3 mb-2"><h3 {...props} className="inline-block border-l-8 mb-0 py-0.5 pl-2 align-middle border-c-main " /></div>,
          }} >

          <MDXRenderer
            localImages={localImages}
            toc={tableOfContents}
            slug={slug} >
            {body}
          </MDXRenderer>
        </MDXProvider>

      </section>
    </LayoutSub>
  );

};

  // highlight-start
export const query = graphql`
query($slug: String) {
  site {
    siteMetadata {
      author
      siteUrl
    }
  }

  mdx(slug: {eq: $slug}) {
    slug
    body
    tableOfContents
    frontmatter {
      title
      lastmod(formatString: "YYYY年M月D日")
      description
      hero_image_alt
      hero_image {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      embeddedImagesLocal {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: TRACED_SVG
          )
        }
      }
    }
    noformat: frontmatter{
      lastmod(formatString: "YYYY-MM-DDTHH:mm:ssZ")
      created(formatString: "YYYY-MM-DDTHH:mm:ssZ")
    }
  }
}
`

export default BlogPost;

export function Head   ({
  data: {
  site: { siteMetadata:{  siteUrl }},
  mdx: { frontmatter: { title,   hero_image,   },
         noformat: {lastmod, created}
       },
      }, 
location: { pathname },
})  {

  const image = getImage(hero_image) 
  const imgSrc = getSrc(image)

  return(
  <>
    <script type="application/ld+json">
    {`{
      "@context": "https://schema.org",
      "@type": "Article",
      "headline": "${title}",
      "image": [
        "${siteUrl}${imgSrc}"
       ],
      "datePublished": "${created}",
      "dateModified": "${lastmod}",
      "author": [{
          "@type": "Person",
          "name": "Tomoko Hashimoto",
          "url": "${siteUrl}/profile/"
      }]
     }
     `}
    </script>

  </>
  )
}