// 記事内広告
import React, { useEffect } from "react"

const AdsenseDisplay = (props) => {
  const { currentPath } = props

  useEffect(() => {
    if (window) {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }
  }, [currentPath])

  return (
    <>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-7649835449254171"
        data-ad-slot="4410435291"
        data-ad-format="horizontal"
        data-full-width-responsive="false" 
      />
    </>
  )
}

export default AdsenseDisplay;
